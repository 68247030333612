/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import User from "./pages/user/User";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Hospital from "./pages/hospital/Hospital";
import DashboardApp from "./pages/DashboardApp";
import { CURRENT_USER, KEY_AUTH_TOKEN } from "./utils/constants";
import { useEffect } from "react";
import UserView from "./pages/user/User.list.view";
import { UserListContextProvider } from "./context/User/User.Context.Provider";
import Useredit from "./pages/user/User.List.Edit";
import { HospitalListContextProvider } from "./context/Hospital/Hospital.Context.Provider";
import Hospitalview from "./pages/hospital/Hospital.view";
import Hospitaledit from "./pages/hospital/Hospital.edit";
import ClientsList from "./pages/clients/Clients.List";
import Clientview from "./pages/clients/Client.view";
import { ClientsContextProvider } from "./context/Client/Clients.Context.Provider";
import Attendance from "./pages/attendance/Attendance";
import { AttendanceListContextProvider } from "./context/Attendance/Attendance.Context.Provider";
import { TicketListContextProvider } from "./context/Ticket/Ticket.Context.Provider";
import Ticket from "./pages/Ticket/Ticket";
import TicketView from "./pages/Ticket/Ticket.View";
import Shoots from "./pages/shoots/Shoots";
import { ShootsContextProvider } from "./context/Shoots/Shoots.Context.Provider";
import Shootsview from "./pages/shoots/Shoots.view";
import { OrderContetxProvider } from "./context/Order/Order.Context.Provider";
import Orderview from "./pages/Order/Order.view";
import Order from "./pages/Order/Order";
import { SalesAnalyticsContextProvider } from "./context/Analytics/Sales.Analytics.Context.Provider";
import { LeavesContextProvider } from "./context/Leaves/Leaves.Context.Provider";
import Leaves from "./pages/Leaves/Leaves";
import Leaveview from "./pages/Leaves/Leave.view";
import Privacypolicy from "./pages/privacy-policy";
import Document from "./pages/document/Document";

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();

  const logInData =
    JSON.parse(localStorage.getItem(CURRENT_USER)) ||
    JSON.parse(sessionStorage.getItem(CURRENT_USER));

  // const logInData = {}

  let tokenOfUser = localStorage.getItem(KEY_AUTH_TOKEN)
    ? localStorage.getItem(KEY_AUTH_TOKEN)
    : sessionStorage.getItem(KEY_AUTH_TOKEN);

  console.log(logInData, tokenOfUser, "this is logIn Data and token");

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (!tokenOfUser && currentPath !== "/privacy-policy") {
      navigate("/login", {
        state: {
          message: "User not already signed in",
        },
        replace: true,
      });
    }
  }, [tokenOfUser]);

  return useRoutes([
    {
      path: "/",
      element: logInData && tokenOfUser ? <DashboardLayout /> : <Login />,
      children:
        logInData && tokenOfUser
          ? [
              {
                path: "dashboard",
                element: tokenOfUser ? (
                  <SalesAnalyticsContextProvider>
                    <DashboardApp />
                  </SalesAnalyticsContextProvider>
                ) : (
                  <Login />
                ),
              },
              {
                path: "users",
                children:
                  logInData?.role === "ADMIN" && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <UserListContextProvider>
                              <User />
                            </UserListContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                      {
                        path: ":userId",
                        children: [
                          {
                            path: "",
                            element:
                              logInData && tokenOfUser ? (
                                <UserView />
                              ) : (
                                <Login />
                              ),
                          },
                          {
                            path: "edit",
                            element:
                              logInData && tokenOfUser ? (
                                <Useredit />
                              ) : (
                                <Login />
                              ),
                          },
                        ],
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "hospitals",
                children:
                  logInData?.role === "ADMIN" && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <HospitalListContextProvider>
                              <Hospital />
                            </HospitalListContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                      {
                        path: ":hospitalId",
                        children: [
                          {
                            path: "",
                            element:
                              logInData && tokenOfUser ? (
                                <Hospitalview />
                              ) : (
                                <Login />
                              ),
                          },
                          {
                            path: "edit",
                            element:
                              logInData && tokenOfUser ? (
                                <Hospitaledit />
                              ) : (
                                <Login />
                              ),
                          },
                        ],
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "clients",
                children:
                  logInData?.role === "ADMIN" && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <ClientsContextProvider>
                              <ClientsList />
                            </ClientsContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                      {
                        path: ":clientId",
                        children: [
                          {
                            path: "",
                            element:
                              logInData && tokenOfUser ? (
                                <Clientview />
                              ) : (
                                <Login />
                              ),
                          },
                        ],
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "attendance",
                children:
                  logInData?.role === "ADMIN" && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <AttendanceListContextProvider>
                              <Attendance />
                            </AttendanceListContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "leaves",
                children:
                  logInData?.role === "ADMIN" && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <LeavesContextProvider>
                              <Leaves />
                            </LeavesContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                      {
                        path: ":leavesId",
                        children: [
                          {
                            path: "",
                            element:
                              logInData && tokenOfUser ? (
                                <Leaveview />
                              ) : (
                                <Login />
                              ),
                          },
                        ],
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "tickets",
                children:
                  logInData && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <TicketListContextProvider>
                              <Ticket />
                            </TicketListContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                      {
                        path: "view/:ticketId",
                        element:
                          logInData && tokenOfUser ? (
                            <TicketListContextProvider>
                              <TicketView />
                            </TicketListContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "shoots",
                children:
                  logInData && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <ShootsContextProvider>
                              <Shoots />
                            </ShootsContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                      {
                        path: ":shootId",
                        element:
                          logInData && tokenOfUser ? (
                            <ShootsContextProvider>
                              <Shootsview />
                            </ShootsContextProvider>
                          ) : (
                            <Login />
                          ),
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "orders",
                children:
                  logInData && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? (
                            <OrderContetxProvider>
                              <Order />
                            </OrderContetxProvider>
                          ) : (
                            <Login />
                          ),
                      },
                      {
                        path: ":orderId",
                        element:
                          logInData && tokenOfUser ? (
                            <OrderContetxProvider>
                              <Orderview />
                            </OrderContetxProvider>
                          ) : (
                            <Login />
                          ),
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "document",
                children:
                  logInData && tokenOfUser ? (
                    [
                      {
                        path: "",
                        element:
                          logInData && tokenOfUser ? <Document /> : <Login />,
                      },
                    ]
                  ) : (
                    <Navigate to="/login" />
                  ),
              },
              {
                path: "login",
                element:
                  logInData && tokenOfUser ? <Navigate to="/" /> : <Login />,
              },
              { path: "404", element: <NotFound /> },
              { path: "*", element: <Navigate to="/404" /> },

              { path: "*", element: <Navigate to="/404" replace /> },
            ]
          : [{ path: "*", element: <Navigate to="/login" /> }],
    },
    {
      path: "/privacy-policy",
      element: <Privacypolicy />,
    },
  ]);
}
