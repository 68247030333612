import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CardHeader,
} from "@mui/material";
import Iconify from "../../components/Iconify";

import Stack from "@mui/material/Stack";
import { viewHospital } from "src/service/hospital.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SkeletonLoader from "src/components/skeleton/Skeleton.Loader";
import { updateLeave, viewLeave } from "src/service/leaves.service";

export default function Leaveview() {
  const { leavesId } = useParams();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState("");

  const handleChange = async (event) => {
    setLeaveStatus(event.target.value);
  };
  const applyChanges = async () => {
    const body = {
      leaveId: leavesId,
      status: leaveStatus,
    };
    const { data } = await updateLeave(body);
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await viewLeave(leavesId);
    console.log(data, "data");
    setInputs(data?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {}, [inputs]);

  return (
    <>
      <Helmet>
        <title>
          {" "}
          {inputs?.name?.split(" ")[0] || "Ticket"}'s detail | Camories{" "}
        </title>
      </Helmet>
      <Card key={inputs?._id} variant="outlined" sx={{ background: "#f1f2f6" }}>
        <CardContent>
          <Typography variant="h4" marginBottom={3} color="primary">
            Leave Details for {inputs?.user?.fullName}
          </Typography>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <>
              <Card>
                <CardContent>
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "right" }}>
                      <Button
                        variant="contained"
                        sx={{ mt: 2, mb: 3, mr: 2, py: 1, px: 2 }}
                        style={{
                          backgroundColor: "white",
                          color: "#EFA8B0",
                          borderColor: "#EFA8B0",
                        }}
                        onClick={() => navigate(-1)}
                      >
                        <ArrowBackIcon />
                        Back
                      </Button>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.user?.fullName || ""}
                          disabled
                          name="fullName"
                          label="Full Name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.user?.empCode || ""}
                          disabled
                          name="empCode"
                          label="Employee Code"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.user?.phNo || "- NA -"}
                          disabled
                          name="phNo"
                          label="Contact Number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.user?.email || "- NA -"}
                          disabled
                          name="email"
                          label="Email"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.leaveType || "- NA -"}
                          disabled
                          name="email"
                          label="Leave Type"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={inputs?.reason || "- NA -"}
                          disabled
                          name="email"
                          label="Leave Reason"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={
                            inputs?.endDate
                              ? new Date(inputs.startDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long", // Displays full month name (e.g., December)
                                    day: "numeric", // Displays the day (e.g., 21)
                                  }
                                )
                              : "- NA -"
                          }
                          disabled
                          name="email"
                          label="Leave start from"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={
                            inputs?.endDate
                              ? new Date(inputs.endDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long", // Displays full month name (e.g., December)
                                    day: "numeric", // Displays the day (e.g., 21)
                                  }
                                )
                              : "- NA -"
                          }
                          disabled
                          name="email"
                          label="Leave end to"
                          fullWidth
                        />
                      </Grid>
                      {inputs?.status === "PENDING" ? (
                        <>
                          <Grid item xs={6}>
                            <FormControl
                              fullWidth
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                // justifyContent: "space-between",
                              }}
                            >
                              <InputLabel>Leave Status</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leaveStatus}
                                label="Age"
                                onChange={handleChange}
                              >
                                <MenuItem value={"APPROVED"}>Approve</MenuItem>
                                <MenuItem value={"REJECTED"}>Reject</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              // justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button variant="outlined" onClick={applyChanges}>
                              Apply
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={6}>
                          <TextField
                            value={inputs?.status || "- NA -"}
                            //   disabled
                            name="email"
                            label="Leave Status"
                            fullWidth
                            InputProps={{
                              style: {
                                color:
                                  inputs?.status === "PENDING"
                                    ? "#ed6c02" // Orange for warning
                                    : inputs?.status === "APPROVED"
                                    ? "#2e7d32" // Green for success
                                    : "#d32f2f", // Red for error
                              },
                              backgroundColor: "red",
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ marginTop: 2 }}>
                <CardContent>
                  <Typography variant="subtitle2" marginBottom={2}>
                    Leave Remaining
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        value={inputs?.user?.leaveBalance?.casualLeaves || ""}
                        disabled
                        name="fullName"
                        label="Casual Leaves"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={
                          inputs?.user?.leaveBalance?.emergencyLeaves || ""
                        }
                        disabled
                        name="fullName"
                        label="Emergency Leaves"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}
