// component
import Iconify from "../../components/Iconify";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CameraIcon from "@mui/icons-material/Camera";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
// // ----------------------------------------------------------------------

// const navConfig = [
//   {
//     title: 'dashboard',
//     path: '/dashboard',
//     icon: getIcon('eva:pie-chart-2-fill'),
//   },
//   {
//     title: 'users',
//     path: '/users',
//     icon: <ManageAccountsIcon/>,
//   },
//   {
//     title: 'hospitals',
//     path: '/hospitals',
//     icon: <LocalHospitalRoundedIcon/>,
//   },
//   {
//     title: 'clients',
//     path: '/clients',
//     icon: <PeopleAltOutlinedIcon/>,
//   },
//   {
//     title: 'attendance',
//     path: '/attendance',
//     icon: <EventAvailableIcon/>,
//   },
//   {
//     title: 'ticket',
//     path: '/tickets',
//     icon: <ConfirmationNumberIcon/>,
//   },
//   {
//     title: 'shoot',
//     path: '/shoots',
//     icon: <CameraIcon/>,
//   },
//   {
//     title: 'order',
//     path: '/orders',
//     icon: <LocalMallIcon/>,
//   },

// ];

// export default navConfig;
import React from "react";

export default function NavConfig(logInData) {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const navConfig = [
    {
      title: "dashboard",
      path: "/dashboard",
      icon: getIcon("eva:pie-chart-2-fill"),
    },
    ...(logInData?.role === "ADMIN"
      ? [
          {
            title: "users",
            path: "/users",
            icon: <ManageAccountsIcon />,
          },
          {
            title: "hospitals",
            path: "/hospitals",
            icon: <LocalHospitalRoundedIcon />,
          },
          {
            title: "clients",
            path: "/clients",
            icon: <PeopleAltOutlinedIcon />,
          },
          {
            title: "attendance",
            path: "/attendance",
            icon: <EventAvailableIcon />,
          },
          {
            title: "leaves",
            path: "/leaves",
            icon: <ChecklistOutlinedIcon />,
          },
        ]
      : []),
    {
      title: "shoot",
      path: "/shoots",
      icon: <CameraIcon />,
    },
    {
      title: "order",
      path: "/orders",
      icon: <LocalMallIcon />,
    },
    {
      title: "ticket",
      path: "/tickets",
      icon: <ConfirmationNumberIcon />,
    },
    {
      title: "document",
      path: "/document",
      icon: <DocumentScannerOutlinedIcon />,
    },
  ];

  return navConfig;
}
