import { CURRENT_USER, KEY_AUTH_TOKEN } from "src/utils/constants";
import axiosInstances from "./axios";

const getToken = () =>
  localStorage.getItem(KEY_AUTH_TOKEN)
    ? localStorage.getItem(KEY_AUTH_TOKEN)
    : sessionStorage.getItem(KEY_AUTH_TOKEN);
const DEFAULT_LIMIT = -1;

const logout = (reason) => {
  console.log("logout is working.....");
  localStorage.removeItem(KEY_AUTH_TOKEN);
  localStorage.removeItem(CURRENT_USER);
  sessionStorage.removeItem(KEY_AUTH_TOKEN);
  sessionStorage.removeItem(CURRENT_USER);
  window.location.reload();
};

const checkError = async (error) => {
  console.log(error?.response?.data, "checkError response------------");
  if (error?.response?.data) {
    const data = error?.response?.data;
    console.log(data, "error response in data");

    if (data?.code === 401) {
      console.log(data?.message, "data message in checkError");
      logout("INVALID_TOKEN");
    }
    return { data };
  }
  return error?.message;
};

export const getAuthSignIn = async (path, body) => {
  try {
    const { data } = await axiosInstances.post(path, body, {
      headers: { "Content-Type": "application/json" },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const createApi = async (path, body) => {
  try {
    const { data } = await axiosInstances.post(path, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const getUsersApi = async (
  path,
  search = "",
  role = "",
  limit = DEFAULT_LIMIT,
  offset = 0,
  status = ""
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?search=${search}&role=${role}&limit=${limit}&offset=${offset}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const deleteApi = async (path) => {
  try {
    const { data } = await axiosInstances.delete(path, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const getDetailsApi = async (path) => {
  try {
    const { data } = await axiosInstances.get(`${path}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1",
      },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const updateApi = async (path, body) => {
  try {
    const { data } = await axiosInstances.put(`${path}`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1",
      },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const isEnabledApi = async (path, body) => {
  try {
    const { data } = await axiosInstances.put(`${path}`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1",
      },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const resetPassApi = async (path, body) => {
  try {
    const { data } = await axiosInstances.post(`${path}`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1",
      },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const getHospitalsApi = async (
  path,
  search = "",
  limit = DEFAULT_LIMIT,
  offset = 0,
  status = ""
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?search=${search}&limit=${limit}&offset=${offset}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};

export const getClientsApi = async (
  path,
  search = "",
  limit = DEFAULT_LIMIT,
  offset = 0
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?search=${search}&limit=${limit}&offset=${offset}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getAttendanceApi = async (
  path,
  empCode = "",
  fromDate = "",
  toDate = "",
  limit = DEFAULT_LIMIT,
  offset = 0
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?empCode=${empCode}&fromDate=${fromDate}&toDate=${toDate}&limit=${limit}&offset=${offset}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getTicketDetailsApi = async (
  path,
  search = "",
  limit = DEFAULT_LIMIT,
  offset = 0,
  status
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?search=${search}&limit=${limit}&offset=${offset}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getShootsDetailsApi = async (
  path,
  search = "",
  fromDate = "",
  toDate = "",
  limit = DEFAULT_LIMIT,
  offset = 0,
  status = "",
  hospital = ""
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?search=${search}&fromDate=${fromDate}&toDate=${toDate}&limit=${limit}&offset=${offset}&status=${status}&hospital=${hospital}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getOrderDetailsApi = async (
  path,
  search = "",
  limit = DEFAULT_LIMIT,
  offset = 0,
  status,
  hospital = "",
  frameDeliveryDate = "",
  softCopyDeliveryDate = ""
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?search=${search}&limit=${limit}&offset=${offset}&status=${status}&hospital=${hospital}&frameDeliveryDate=${frameDeliveryDate}&softCopyDeliveryDate=${softCopyDeliveryDate}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getSalesAmountAnalyticsApi = async (
  path,
  fromDate,
  toDate,
  status
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}&fromDate=${fromDate}&toDate=${toDate}${status}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getSalesPackageAnalyticsApi = async (
  path,
  fromDate,
  toDate,
  status
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}&fromDate=${fromDate}&toDate=${toDate}${status}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getAnalyticsDetailsApi = async (path) => {
  try {
    const { data } = await axiosInstances.get(`${path}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1",
      },
    });
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getReportApi = async (path, fromDate, toDate, hospitalId) => {
  try {
    const result = await axiosInstances.get(
      `${path}/order-export?fromDate=${fromDate}&toDate=${toDate}&hospitalId=${hospitalId}`,
      {
        // headers: setHeaders(),
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          // "ngrok-skip-browser-warning": "1",
        },
        responseType: "blob",
      }
    );
    return { data: result?.data };
  } catch (error) {
    console.log(error, "catch..");
    // return checkError(error);
    return null;
  }
};
export const getLeavesApi = async (
  path,
  empCode = "",
  fromDate = "",
  toDate = "",
  limit = DEFAULT_LIMIT,
  offset = 0,
  status = ""
) => {
  try {
    const { data } = await axiosInstances.get(
      `${path}?empCode=${empCode}&fromDate=${fromDate}&toDate=${toDate}&limit=${limit}&offset=${offset}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      }
    );
    return Promise.resolve({
      isSuccess: true,
      data,
    });
  } catch (error) {
    return checkError(error);
  }
};
export const getDocumentApi = async (path) => {
  try {
    const result = await axiosInstances.get(`${path}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1",
      },
      responseType: "blob",
    });
    return Promise.resolve({
      isSuccess: true,
      result,
    });
  } catch (error) {
    return checkError(error);
    // return error?.message;
  }
};
