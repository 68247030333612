import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { saveAs } from "file-saver";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getHospitals } from "src/service/hospital.service";
import dayjs from "dayjs";
import { useState } from "react";
import { getUsers } from "src/service/user.service";
import { toast } from "react-toastify";
import { getDocumentApi } from "src/service/api.request";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Document() {
  const [value, setValue] = React.useState(0);
  const [hospitalId, setHospitalId] = React.useState("");
  const [hospitalList, setHospitalList] = React.useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userData, setUserData] = useState([]);
  const [empCodeData, setEmpCodeData] = useState("");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getHospitalsList = async () => {
    const { data } = await getHospitals("", -1, 0, "");
    setHospitalList(data?.data?.items);
  };
  const getAllUsers = async () => {
    const { data } = await getUsers("", "", -1, 0);
    setUserData(data?.data?.items?.filter((item) => item?.role !== "ADMIN"));
  };

  const handleSummaryReport = async () => {
    try {
      console.log(
        hospitalId,
        startDate,
        endDate,
        "pdf api data",
        selectedValue,
        toDate,
        fromDate
      );

      let missingFields = [];

      if (value === 0) {
        if (!hospitalId) missingFields.push("Hospital");
        if (!startDate) missingFields.push("Start Date");
        if (!endDate) missingFields.push("End Date");
      } else if (value === 1) {
        if (!selectedValue) missingFields.push("User");
        if (!fromDate) missingFields.push("From Date");
        if (!toDate) missingFields.push("To Date");
      }

      // Show a single toast message if any fields are missing
      if (missingFields.length > 0) {
        toast.error(`Please Select: ${missingFields.join(", ")}`);
        return;
      }
      setLoader(true);
      const entity =
        value === 0
          ? `order-label?hospital=${
              hospitalId._id
            }&fromDate=${startDate.valueOf()}&toDate=${endDate.valueOf()}`
          : `attendance-report?empCode=${
              selectedValue.empCode
            }&fromDate=${fromDate.valueOf()}&toDate=${toDate.valueOf()}`;

      // Fetch the document
      const { isSuccess, result } = await getDocumentApi(`apis/docs/${entity}`);

      if (!isSuccess || !result || !result.data) {
        throw new Error(
          "Error Downloading File or No Data Exists For Given Values"
        );
      }
      const formatDate = (date) => {
        return new Date(date.$d)
          .toLocaleDateString("en-GB")
          .replace(/\//g, "-");
      };
      const fileName =
        value === 0
          ? `Order_Label_${hospitalId.name}_${formatDate(
              startDate
            )}_To_${formatDate(endDate)}.pdf`
          : `Attendance_Report_${selectedValue.fullName}_${formatDate(
              fromDate
            )}_To_${formatDate(toDate)}.pdf`;

      // Convert response to Blob
      const blob = new Blob([result.data], { type: "application/pdf" });

      console.log("Blob Data:", blob);
      console.log("Blob Size:", blob.size);
      // Use Blob in saveAs

      if (blob.size > 0) {
        console.log("if statement block");
        setLoader(false);

        saveAs(blob, fileName);
        // saveAs(
        //   blob,
        //   `Order_Label_${hospitalId.name}${formatDate(
        //     startDate
        //   )} To ${formatDate(endDate)}.pdf`
        // );

        // Open in new tab
        const newTab = window.open("", "_blank");
        if (newTab) {
          const fileURL = URL.createObjectURL(blob);
          newTab.location.href = fileURL;
          console.log(fileURL, "location----------");

          setTimeout(() => {
            newTab.document.title = `Order_Label`;
          }, 1000);
        } else {
          toast.error("Popup blocked. Please allow popups to view the file.");
        }
      } else {
        throw new Error("Empty PDF file received.");
      }
    } catch (error) {
      console.error("Error downloading PDF:", error.message);
      toast.error(error?.message || "Unable to download due to an error.");
      setLoader(false);
    }
  };
  React.useEffect(() => {
    getHospitalsList();
    getAllUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Documents | Camories</title>
      </Helmet>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* Table Header with Tabs */}
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={100}
                sx={{ padding: 0, backgroundColor: " rgba(234, 234, 234, 1)" }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="table tabs"
                  >
                    <Tab label="Order Label" />
                    <Tab label="Attendance Report" />
                    {/* <Tab label="Hospital Sales Report" /> */}
                  </Tabs>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body (Placeholder Content) */}
          <TableBody>
            <TableRow
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100%",
              }}
            >
              {/* <TableCell>Data 1</TableCell>
            <TableCell>Data 2</TableCell>
            <TableCell>Data 3</TableCell> */}
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ width: "50vw" }}>
                  <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                            name="hospital"
                            onChange={(e, newValue) => setHospitalId(newValue)}
                            fullWidth
                            value={hospitalId || ""}
                            options={hospitalList || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Hospitals"
                                required
                              />
                            )}
                            getOptionLabel={(options) =>
                              options?._id ? ` ${options?.name}` : ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option?._id === value?._id
                            }
                            getOptionSelected={(option, value) =>
                              option?._id === value?._id
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1" paddingBottom={2}>
                        Select Frame Delivery Date In Between
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Start Date"
                              value={startDate}
                              name="startDate"
                              format="DD/MM/YYYY"
                              onChange={(newStartDate) => {
                                if (newStartDate)
                                  return setStartDate(
                                    dayjs(newStartDate).startOf("day")
                                  );
                                return setStartDate(null);
                              }}
                              sx={{ width: "100% !important" }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="End Date"
                              value={endDate}
                              name="startDate"
                              format="DD/MM/YYYY"
                              onChange={(newEndDate) => {
                                if (newEndDate)
                                  return setEndDate(
                                    dayjs(newEndDate).endOf("day")
                                  );
                                return setEndDate(null);
                              }}
                              sx={{ width: "100% !important" }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Box
                      paddingBottom={2}
                      paddingRight={3}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          backgroundColor: "white",
                          color: "#74BAB6",
                          borderColor: "#74BAB6",
                          width: "150px",
                        }}
                        onClick={handleSummaryReport}
                        disabled={loader}
                      >
                        {loader ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Apply"
                        )}
                      </Button>
                    </Box>
                  </Card>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box sx={{ width: "50vw" }}>
                  <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                            id="empCodeData"
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setEmpCodeData(newValue?.empCode);
                                setSelectedValue(newValue);
                              } else {
                                setEmpCodeData("");
                                setSelectedValue("");
                              }
                            }}
                            defaultValue={""}
                            value={selectedValue || ""}
                            name="empCode"
                            options={userData || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="User List"
                                required
                              />
                            )}
                            getOptionLabel={(option) => option.fullName || ""}
                            isOptionEqualToValue={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option?.empCode === value?.empCode
                            }
                            getOptionSelected={(option, value) =>
                              option?.empCode === value?.empCode
                            }
                            sx={{
                              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                                {
                                  backgroundColor: "#fff",
                                },
                              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                                {
                                  backgroundColor: "#f6f6f6",
                                },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1" paddingBottom={2}>
                        Select Attendance Report Date
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="From Date"
                              value={fromDate}
                              name="startDate"
                              format="DD/MM/YYYY"
                              disableFuture
                              onChange={(newStartDate) => {
                                if (newStartDate)
                                  return setFromDate(
                                    dayjs(newStartDate).startOf("day")
                                  );
                                return setFromDate(null);
                              }}
                              sx={{ width: "100% !important" }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="To Date"
                              value={toDate}
                              name="startDate"
                              format="DD/MM/YYYY"
                              disableFuture
                              onChange={(newEndDate) => {
                                if (newEndDate)
                                  return setToDate(
                                    dayjs(newEndDate).endOf("day")
                                  );
                                return setToDate(null);
                              }}
                              sx={{ width: "100% !important" }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Box
                      paddingBottom={2}
                      paddingRight={3}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          backgroundColor: "white",
                          color: "#74BAB6",
                          borderColor: "#74BAB6",
                          width: "150px",
                        }}
                        onClick={handleSummaryReport}
                        disabled={loader}
                      >
                        {loader ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Apply"
                        )}
                      </Button>
                    </Box>
                  </Card>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Item Three
              </CustomTabPanel>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
